import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import './scss/style.scss'
import { Styles } from "./styles/index";
import {message} from 'antd'
import routesMap from 'src/routesMap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons'
import { LoaderContainer, Loader } from './views/LandingPage/components/styles';

import SwiperCore, { Autoplay, Navigation, FreeMode, HashNavigation, Lazy} from 'swiper';

SwiperCore.use([Autoplay, Navigation, FreeMode, HashNavigation, Lazy]);

library.add(fas)
library.add(fab)
library.add(far)

message.config({duration: 5});

const loading = (
  <LoaderContainer>
    <Loader />
  </LoaderContainer>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Home = React.lazy(() => import('./views/LandingPage/Home'));
const VerifyEmail = React.lazy(() => import('./views/LandingPage/VerifyEmail'));
const LoginDiscord = React.lazy(() => import('./views/LandingPage/LoginDiscord'));
const LoginTwitter = React.lazy(() => import('./views/LandingPage/LoginTwitter'));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Styles />
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route path={routesMap.defaultLayout.path} name={routesMap.defaultLayout.name} render={(props) => <DefaultLayout {...props} />} />
            <Route exact path={routesMap.verifyEmail.path} name={routesMap.verifyEmail.name} render={props => <VerifyEmail {...props}/>} />
            <Route exact path={routesMap.loginTwitter.path} name={routesMap.loginTwitter.name} render={props => <LoginTwitter {...props}/>} />
            <Route exact path={routesMap.loginDiscord.path} name={routesMap.loginDiscord.name} render={props => <LoginDiscord {...props}/>} />
            <Route path={routesMap.home.path} name={routesMap.home.name} render={props => <Home {...props}/>} />
            <Redirect to="/404" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}

export default App
