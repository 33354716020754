import constants from 'src/utils/constants'

export const LOCALE_EN = {
    "home": "Home",
    "routes": {
      "home": "Home",
      "collectorInfo": "Collector information",
      "creatorInfo": "Creator information",
      "verifyEmail": "Verify Email",
      "dashboard": "Dashboard",
      "default": "Collectors",
      "login": "Login",
      "administration": "Administration",
      "commons": "Commons",
      "profile": "Profile",
      "profileCreator": "Creator",
      "collection": "Collection",
      "loginDiscord": "Login Discord",
      "loginTwitter": "Login Twitter",
      "faqs": "FAQs",
      "terms": "Terms And Conditions",
      "privacy": "Privacy Policy"
    },
    "profilenav": {
      "account": {
        "title": "Account",
        "profile": "Profile",
        "logout": "Logout"
      }
    },
    "views": {
      "login": {
        "title": "Login",
        "description": constants.app_description,
        "messages": {
          "success_login": "Invalid username or invalid password"
        }
      },
      "profile": {
        "title": "Collector",
        "creator_title": "Creator",
        "section_wallet_title": "Wallet",
        "section_email_title": "Email",
        "section_email_subtitle": "Get alerts about lists you've joined and updates from WHITELIST",
        "section_social_title": "Social Accounts",
        "section_social_subtitle": "Some projects ask you to connect a social network during the registration process. You can only connect a specific social account to one {{projectName}} account",
        "text_1": "Primary",
        "text_2": "Email not provided!",
        "text_3": "Notification",
        "text_4": "Notification",
        "text_5": "Add Email",
        "text_5b": "Edit Email",
        "text_6": "Enable notifications",
        "text_7": "Disable notifications",
        "text_8": "Anti-Phishing Code",
        "text_9": "Please Add",
        "text_10": "Change",
        "text_11": "What is this?",
        "text_12": "This is a word/string that only you know, and we will include prominently in every email we send you. That way, you'll know it's officialy from us",
        "text_13": "Not Connected",
        "text_14": "Connected to",
        "text_15": "Disconnect",
        "text_16": "Connect",
        "text_17": "Hello",
        "text_18": "Your email has been verified correctly",
        "text_18b": "Your email has not been verified correctly",
        "text_19": "Email verified",
        "text_20": "Email not verified",
        "text_21": "¿Are you sure to enable notifications?",
        "text_22": "¿Are you sure to disable notifications?",
        "text_23": "Confirmation",
        "success_activate_package": "Your free package has been activated",
        "error_activate_package": "Your free package has not been activated",
        "messages": {
          "error_getting_profile": "An error occurred while trying to get your profile",
          "error_update_email" : "An error occurred while updating the email",
          "success_update_email" : "The email has been updated successfully",
          "error_verify_email" : "An error occurred while verifying the email",
          "error_enable_notification" : "An error occurred while updating your notification preferences",
          "success_enable_notification" : "Notifications enabled",
          "success_disable_notification" : "Notifications disabled",
          "success_update_anti_phising_code": "The code has been updated successfully",
          "error_update_anti_phising_code": "An error occurred while updating the code",
          "error_discord_login": "An error occurred while connect to discord",
          "error_discord_logout": "An error occurred while disconnect to discord",
          "success_discord_login": "Discord Login success",
          "success_discord_logout": "Discord Logout success",
          "success_twitter_login": "Twitter Login success",
          "success_twitter_logout": "Discord Logout success",
          "error_twitter_logout": "An error occurred while disconnect to discord",
          "error_twitter_login": "An error occurred while connect to twitter"
        },
        "email_form": {
          "title": "Add Email",
          "fields": {
            "email": {
              "name": "Email",
              "placeholder": "jhondoe@example.com"
            }
          }
        },
        "phisingcode_form": {
          "title": "Add Anti Phishing Code",
          "fields": {
            "anti_phishing_code": {
              "name": "Code",
              "placeholder": "Code"
            }
          }
        }
      },
      "profileCreator": {
        "title": "Titular de lo que se va a pedir",
        "text_1": "Dejanos un email para que nuestro equipo pueda contactarte y de esa forma conocer tu magnifica colección y darle acceso a las herramientas que whitewale tiene para ti",
        "text_2": "Lorem ipsum if sur randy mu ring fry as I lopp. A thus re.",
        "text_3": "Lorem ipsum if sur randy mu ring fry as I lopp. A thus re.",
        "buttons": {
          "submit_form": "Create Account",
        },
        "messages": {
          "error_getting_profile": "An error occurred while trying to get your profile",
          "error_getting_packages": "An error occurred while trying to get available packages",
          "error_getting_collections": "An error occurred while trying to get your collections",
          "error_save_package": "An error occurred while trying to save free package",
          "succes_save_package": "We have you sent email to activate free package",
          "error_submit_package_creator": "No options allowed. Please reload the page",
          "error_max_collection_limit": "The package free only allow one collection. Upgrade to Package Premium"
        },
        "fields": {
          "email": {
            "name": "Email",
            "placeholder": "whitelist@gmail.com",
            "error_required": "Email es requerido"
          },
          "package_id": {
            "name": "Estoy interesado en el paquete",
            "placeholder": "Seleccione",
            "error_required": "Paquete es requerido"
          }
        }
      },
      "collectionPreviewList": {
        "text_1": "List You've Joined",
        "text_2": "Your collections",
        "text_3": "See all",
        "text_4": "Add a collection",
        "text_5": "Upgrade"
      },
      "collection": {
        "messages": {
          "error_getting_modules": "An error occurred while trying to get available modules",
          "add_validation": "Please, select a option first",
          "add_validation_exist": "The validation is already added",
          "select_first_twitter": "Please first need you add a twitter sign",
          "select_first_discord": "Please first need you add a discord sign",
          "error_getting_timezones": "An error occurred while trying to get available timezones",
          "error_getting_discord_servers": "An error occurred while trying to get discord servers",
          "error_getting_discord_roles": "An error occurred while trying to get discord roles",
          "error_login_discord": "Please, first connect your discord account in your profile",
          "error_getting_collection": "An error occurred while trying to get a collection",
          "error_getting_collector_status": "An error occurred while trying to get a collector status"
        },
        "form": {
          "title_section_1": "Name and category of the collection",
          "collection_name": "Collection name",
          "name_category_nft": "Name a category that references NFT",
          "name_network_nft": "Network that references NFT",
          "description": "Description",
          "mint_day": "Mint day",
          "title_section_2": "Social Media",
          "twitter": "Twitter",
          "discord": "Discord",
          "web": "Web page",
          "title_section_3": "Configure your whitelist",
          "opening_day_time": "Participate whitelist opening day and time",
          "opening_date": "Date",
          "opening_time": "Time",
          "opening_timezone": "Timezone",
          "type_list": "Type of whitelist",
          "maximum_participants": "Maximum number of participants",
          "number_winners": "Number of winners",
          "add_validation": "Add validation for you whitelist",
          "title_section_4": "Images",
          "important": "Important",
          "important_message": "Once published you will no longer be able to make changes to your collection and it will be made public on our website",
          "logo_image": "Logo"
        }

      },
      "collection_render": {
        "messages": {
          "success_collection_register": "Success collection register",
        },
        "form": {
          "title_password": "This page is password protected",
        },
        "alert_title": "Details",
        "alert_title_not_opening": "The collection open {{dateTime}} with zone {{timeZone}}",
        "section_validations_title": "Rellena los campos para poder seguir",
        "txt_registered": "You are registered",
        "txt_check_status": "Check your Status",
        "txt_check_status_approved_title": "Congratulations!",
        "txt_check_status_disapproved_title": "I am sorry!",
        "txt_check_status_pending_title": "Waiting!",
        "txt_check_status_approved_subtitle": "Your are in the whitelist of",
        "txt_check_status_disapproved_subtitle": "Your are not in the whitelist of",
        "txt_check_status_pending_subtitle": "Your are waiting to access to the whitelist of",
        "txt_check_status_content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s."
      }
    },
    "page":{
      "header": {
        "title": 'Whitelist Whale'
      },
      "home":{
        "block1": {
          "title": 'Unlimited whitelist to 100+ instructors'
        },
        "block2": {
          "collector":{
            "title": "Collector",
            "subtitle": "What you can do as a collector",
            "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s."
          },
          "creator":{
            "title": "Creator",
            "subtitle": "What you can do as a creator",
            "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s."
          },
          "btn_title": "Learn more"
        },
        "block3": {
          "title": 'If you have questions',
          "btn_title": "See FAQs"
        }
      },
      "collector": {
        "title": "Collector",
        "title2": "Join us as Collector",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque cursus porta pharetra. Quisque eu bibendum lorem, nec varius tortor. Praesent neque odio, euismod ut mi eget. Quisque cursus porta pharetra. Quisque eu bibendum lorem, nec varius tortor. Praesent neque odio, euismod ut mi eget.",
        "title_icon1": "Access hundreds of collections",
        "title_icon2": "No Gas costs, actively participate",
        "title_icon3": "Get a space in the whitelist of your favorite collections",
        "btn_title": "Start Now"
      },
      "creator": {
        "title": "Creator",
        "title2": "Join us as Creator",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque cursus porta pharetra. Quisque eu bibendum lorem, nec varius tortor. Praesent neque odio, euismod ut mi eget. Quisque cursus porta pharetra. Quisque eu bibendum lorem, nec varius tortor. Praesent neque odio, euismod ut mi eget.",
        "image_title": "GET COLLECTORS, NOT BOTS",
        "password_title": "Password",
        "nft_title": "NFT Ownership",
        "recaptcha_title": "ReCaptcha",
        "twitter_title": "Twitter Auth",
        "discord_title": "Discord Membership"
      },
      "footer": {
        "title": 'WhitelistWhale',
        "term": 'Term',
        "privacy": 'Privacy',
      }
    },
    "common": {
      "search_filter": {
        "buttons": {
          "search": "Search",
          "reset": "Clean"
        },
        "placeholder": "Search"
      },
      "buttons": {
        "save": "Save",
        "post": "Post",
        "edit": "Edit",
        "delete": "Delete",
        "activate": "Activate",
        "cancel": "Cancel",
        "add": "Add",
        "new": "New",
        "refresh": "Refresh",
        "update": "Update",
        "single": "View details",
        "back": "Back",
        "export": "Export",
        "report": "Report",
        "skip": "Skip",
        "wallet": "Connect wallet",
        "metamask_wallet": "Connect Metamask",
        "connecting": "Connecting...",
        "unlock": "Unlock Page",
        "gohome": "Go Home",
        "connect": "Connect",
        "validate": "Validate",
        "connect_twitter": "Twitter connect",
        "connect_discord": "Discord connect",
        "register": "Click To Register",
        "check_status": "Check Status",
        "buy": "Buy Package",
        "export_csv": "Export CSV",
        "randomWinners": "Random Winners",
        "continue": "Continue",
      },
      "tooltips": {
        "edit": "Edit",
        "delete": "Delete",
      },
      "status": {
        "active": "Active",
        "inactive": "Inactive",
        "pending": "Pending",
      },
      "table": {
        "name": "Name",
        "type": "Type",
        "created_at": "Created at",
        "description": "Description",
        "actions": "Actions",
        "start_date": "Start date",
        "end_date": "End date"
      },
      "modal_confirm_question": {
        "confirm": "Confirm",
        "cancel": "Back"
      }
    },
    "package_modules": {
      "connect_wallet": "Connect wallet",
      "require_twitter_sign_in": "Connect Twitter",
      "require_discord_sign_in": "Connect Discord",
      "password_protection": "Password Protection",
      "custom_fields": "Custom Fields",
      "recaptcha": "reCAPTCHA",
      "verify_token_ownership": "NFT Validation",
      "registration_scheduling": "Registration Scheduling",
      "verify_discord_membership": "Discord Member",
      "verify_discord_role": "Discord Role",
      "verify_discord_invites": "Discord invites",
      "verify_twitter_follow": "Twitter Follow",
      "verify_twitter_hashtag": "Twitter Hashtag"
      
    },
  }