import styled, { keyframes } from 'styled-components';
import { fadeIn, fadeInLeft, fadeInRight } from 'react-animations';
import { device } from './device';
import { DatePicker, TimePicker, Modal } from 'antd'

const FadeInAnimation = keyframes`${fadeIn}`;
const FadeLeftAnimation = keyframes`${fadeInLeft}`;
const FadeRightAnimation = keyframes`${fadeInRight}`;

/* Animations */
export const FadeIn = styled("div")`
	animation: 1s ${FadeInAnimation};
`;

export const FadeLeft = styled("div")`
	animation: 1s ${FadeLeftAnimation};
`;

export const FadeRight = styled("div")`
	animation: 1s ${FadeRightAnimation};
`;
/* End Animations */


export const BlockContainer = styled("section")`	
	padding-top: ${ props => props.paddingTop || '4rem'};
	padding-bottom: ${ props => props.paddingBottom || '7rem'};
	height: ${props=>props.height||'50vh'};
	position: relative;
`;

export const Title = styled("h1")`
	color: var(${props=>props.color === 'primary' ? '--primary-color' : '--white-color'});
	font-family: 'Gill Sans Bold';
	font-style: bold;
	font-weight: 900;
	font-size: 58px;
	line-height: 60px;
	
	@media ${device.tablet} { 
		font-size: 60px;
	}
	
	@media ${device.laptop} { 
		font-size: 62px;
	}	
	
	@media ${device.desktop} { 
		font-size: 66px;
	}
	
	@media ${device.bigdesktop} { 
		font-size: 68px;
	}
`;

export const Subtitle = styled("h2")`
	color: #FFFFFF;
	font-family: 'Gill Sans Bold';
	font-style: normal;
	font-weight: bold;
	font-size: 52px;
	line-height: 10px;
	
	@media ${device.tablet} { 
		font-size: 54px;
	}
	
	@media ${device.laptop} { 
		font-size: 56px;
	}	
	
	@media ${device.desktop} { 
		font-size: 58px;
	}
	
	@media ${device.bigdesktop} { 
		font-size: 60px;
	}
	
`;

export const H2 = styled("h2")`
	color: var(--primary-color);
	font-family: ${props=>props.type==='normal' ? 'Gill Sans' : 'Gill Sans Bold'};
	font-style: normal;
	font-weight: ${props=>props.weight||'bold'};
	font-size: 52px;

	@media ${device.tablet} { 
		font-size: 54px;
	}
	
	@media ${device.laptop} { 
		font-size: 56px;
	}	
	
	@media ${device.desktop} { 
		font-size: 58px;
	}
	
	@media ${device.bigdesktop} { 
		font-size: 60px;
	}
	
`;

export const H3 = styled("h3")`
	font-size   : 24px;
	font-family : ${props=>props.bold ? "Gill Sans Bold" : "Gill Sans"};
	font-weight : bold;	
	line-height: 1.2;

	@media ${device.tablet} { 
		font-size: 24px;
	}
	
	@media ${device.laptop} { 
		font-size: 26px;
	}	
	
	@media ${device.desktop} { 
		font-size: 28px;
	}
	
	@media ${device.bigdesktop} { 
		font-size: 30px;
	}
	
`;

export const H4 = styled("h4")`
	font-size   : 20px;
	font-family : ${props=>props.bold ? "Gill Sans Bold" : "Gill Sans"};
	font-weight : bold;	
	line-height: 1.2;

	@media ${device.tablet} { 
		font-size: 20px;
	}
	
	@media ${device.laptop} { 
		font-size: 22px;
	}	
	
	@media ${device.desktop} { 
		font-size: 24px;
	}
	
	@media ${device.bigdesktop} { 
		font-size: 26px;
	}
	
`;

export const H5 = styled("h5")`
	font-size   : 16px;
	font-family : ${props=>props.bold ? "Gill Sans Bold" : "Gill Sans"};
	line-height: 1.2;
	margin: 0px;

	@media ${device.tablet} { 
		font-size: 16px;
	}
	
	@media ${device.laptop} { 
		font-size: 18px;
	}	
	
	@media ${device.desktop} { 
		font-size: 20px;
	}
	
	@media ${device.bigdesktop} { 
		font-size: 22px;
	}
	
`;

export const H6 = styled("h6")`
	font-size   : 12px;
	font-family : ${props=>props.bold ? "Gill Sans Bold" : "Gill Sans"};
	line-height: 1.2;
	margin: 0px;

	@media ${device.tablet} { 
		font-size: 12px;
	}
	
	@media ${device.laptop} { 
		font-size: 14px;
	}	
	
	@media ${device.desktop} { 
		font-size: 16px;
	}
	
	@media ${device.bigdesktop} { 
		font-size: 18px;
	}
	
`;

export const P = styled("p")`
	font-family : ${props=>props.bold ? "Ubuntu Bold" : "Ubuntu"};
	font-weight: 300;
	font-size: 14px;
	line-height: 36px;

	@media ${device.tablet} { 
		font-size: 14px;
	}
	
	@media ${device.laptop} { 
		font-size: 16px;
	}	
	
	@media ${device.desktop} { 
		font-size: 18px;
	}
	
	@media ${device.bigdesktop} { 
		font-size: 20px;
	}
	
`;

export const Span = styled("span")`
	font-family : ${props=>props.bold ? "Ubuntu Bold" : "Ubuntu"};
	font-weight: 300;
	font-size: 16px;
	line-height: 36px;

	@media ${device.tablet} { 
		font-size: 14px;
	}
	
	@media ${device.laptop} { 
		font-size: 14px;
	}	
	
	@media ${device.desktop} { 
		font-size: 14px;
	}
	
	@media ${device.bigdesktop} { 
		font-size: 16px;
	}
	
`;

export const DatePickerCustom = styled(DatePicker)`
    width: 100%;
	position: relative;
	
	.ant-picker-input > input {
		margin-left: 25px;
	}

	.ant-picker-suffix {
		color: rgba(0, 0, 0, 0.85);
		pointer-events: none;
		width: 100%;
		position: absolute;
    	display: inline-block;
	}

	.ant-form-item-feedback-icon-success {
		float: right;
	}

	.ant-form-item-feedback-icon-error {
		float: right;
	}
`;

export const TimePickerCustom = styled(TimePicker)`
    width: 100%;
	position: relative;
	
	.ant-picker-input > input {
		margin-left: 25px;
	}

	.ant-picker-suffix {
		color: rgba(0, 0, 0, 0.85);
		pointer-events: none;
		width: 100%;
		position: absolute;
    	display: inline-block;
	}

	.ant-form-item-feedback-icon-success {
		float: right;
	}

	.ant-form-item-feedback-icon-error {
		float: right;
	}
`;

export const ModalCustom = styled(Modal)`
    border-radius: 50px;
	width: 400px !important;

	.ant-modal-header {
		background: none;
		border-bottom: none;
	}

	.ant-modal-content {
		border-radius: 10px;
		// background-color: transparent;
		// width: 400px;
	}

	.ant-modal-footer {
		border-top: none;
	}

	.ant-modal-body {
		padding-top: 0px;
	}
`;

export const LoaderContainer = styled('div')`
	position: fixed;
	top: 0;
	bottom: 0;
	background-color: #fff;
	z-index: 99999999;
	width: 100%;
	height: 100%;
`

export const Loader = styled('div')`
	width: 100px;
	height: 100px;
	position: absolute;
	background-image: url(/img/svg/logo.svg);
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	justify-content: center;
	align-content: center;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	-webkit-animation:spin 4s linear infinite;
	-moz-animation:spin 4s linear infinite;
	animation:spin 4s linear infinite;

	@-moz-keyframes spin { 
        100% { -moz-transform: rotate(-360deg); } 
    }
    @-webkit-keyframes spin { 
        100% { -webkit-transform: rotate(-360deg); } 
    }
    @keyframes spin { 
        100% { 
            -webkit-transform: rotate(-360deg); 
            transform:rotate(-360deg); 
        } 
    }
`
export const ImageContainer = styled('div')`
  position: relative;
  margin-top: -25px;
	// position: absolute;
	// top: 55%;
	// left: 25%;
	// transform: translate(-50%, -50%);
  
  background-image: url(${props=>props.url ? props.url : ''}) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  
  &:hover {
    cursor: ${props=>props.url ? '' : 'pointer'};
  }  

  width: 225px;
  height: 225px;
  border: 1px solid #000000;
  border-radius: 20px;

  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  background: #FFFFFF;

  padding: 100px 0;

  z-index: 1;
`

export const ImageContainerSmall = styled('div')`
  position: relative;
  &:hover {
    cursor: ${props=>props.url ? '' : 'pointer'};
  }

  margin: 0 auto;

  background-image: url(${props=>props.url ? props.url : ''}) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  width: 130px;
  height: 130px;
  border: 1px solid #000000;
  border-radius: 20px;

  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  background: #FFFFFF;

  z-index: 1;

  padding: 45px 0;

`;

export const Circle = styled('div')`
  width: 45px;
  height: 45px;  
  border-radius: 50%;
  text-align: center;  
  background: #FFFFFF;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg path {
    stroke: #00A300;
    stroke-width: 150px;
	}

`;

export const StyleButton = styled('div')`
  	box-sizing: border-box;

	display: flex;
	float: right;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 20px;

	position: relative;
	width: min(180px, 100%);
	height: 52px;

	background: #FFFFFF;
	border-radius: 50px;

`;

export const TxtWinner = styled('span')`
	font-family: 'Gill Sans Bold';
	font-style: normal;
	font-weight: 700;
	font-size: 35px;

	background: linear-gradient(121.71deg, #DFBB67 3.79%, #503800 110.22%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
`
