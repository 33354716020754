import i18n from 'src/locale/i18n'

const routesMap = {
  home: {
    path: '/',
    name: i18n.t('routes.home'),
  },
  login: {
    path: '/login',
    name: i18n.t('routes.login'),
  },
  collectorInfo: {
    path: '/collector-info',
    name: i18n.t('routes.collectorInfo'),
  },
  creatorInfo: {
    path: '/creator-info',
    name: i18n.t('routes.creatorInfo'),
  },
  loginDiscord: {
    path: '/login/discord',
    name: i18n.t('routes.loginDiscord'),
  },
  loginTwitter: {
    path: '/login/twitter',
    name: i18n.t('routes.loginTwitter'),
  },
  verifyEmail: {
    path: '/verify-email',
    name: i18n.t('routes.verifyEmail'),
  },
  faqs: {
    path: '/faqs',
    name: i18n.t('routes.faqs'),
  },
  terms: {
    path: '/terms-conditions',
    name: i18n.t('routes.terms'),
  },
  privacy: {
    path: '/policy-privacy',
    name: i18n.t('routes.privacy'),
  },
  defaultLayout: {
    path: '/panel',
    name: i18n.t('routes.default'),
  },
  collection: {
    path: '/panel/collection',
    name: i18n.t('routes.collection'),
  },
  collection_edit: {
    path: '/panel/collection/:id',
    name: i18n.t('routes.collection'),
  },
  collection_preview: {
    path: '/panel/collection/:slug/preview',
    name: i18n.t('routes.collection'),
  },
  profile: {
    path: '/panel/profile-collector',
    name: i18n.t('routes.profile')
  },
  profileCreator: {
    path: '/panel/profile-creator',
    name: i18n.t('routes.profileCreator')
  },
  collectionRender: {
    path: '/:slug',
    name: i18n.t('routes.collection'),
  },
  collectionCollector: {
     path: '/panel/collection/:slug/collectors',
    name: i18n.t('routes.collection'),
  },
}


export default routesMap