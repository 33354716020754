
const constants = {
  app_name: 'Whitelist Customer',
  app_description: 'Whitelist Dashboard Customer',
  app_logo: null,
  app_address: '',
  app_phone_1: '',
  app_authors: 'Webmaster',
  app_email: 'contact@example.com',
  app_copyright: 'Webmaster © 2021. All rights reserved',
  api_url: process.env.REACT_APP_BASE_URL,
  response: {
    error: 'ERROR',
    success: 'OK',
  },
  upload_types: {
    user: "user",
    tmp: "tmp",
  },
  modules: {}
}

export default constants